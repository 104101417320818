import { userStore } from '@development/cluster-center';
import { useColorMode } from 'bootstrap-vue-next';


export default [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: "Login",
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'login'
        }
    },
    {
        path: "/password/reset/:token",
        name: "password-reset",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: "Passwort zurücksetzen",
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'password-reset'
        }

    },
    {
        path: "/password/forgot",
        name: "password-forgot",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/LoginPage.vue"),
        meta: {
            title: "Passwort vergessen",
            middleware: ['isAuthenticated'],
        },
        props: {
            type: 'password-forgot'
        }
    },
    {
        path: "/registration/:type/:id/:email/:token",
        name: "register-user",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/RegisterUserPage.vue"),
        meta: {
            title: "Register",
            middleware: ['isAuthenticated'],
        }
    },
    {
        path: "/invitation/:type/:token/:email/:id",
        name: "invitation",
        component: () => import(/* webpackChunkName: "Auth" */ "@/pages/Auth/InvitationPage.vue"),
        meta: {
            title: "Einladung annehmen",
            middleware: ['isAuthenticated'],
        }
    },
    {
        path: "/impersonate-two-factor-challenge/:userId/:token",
        name: "impersonate",
        component: () => import(/* webpackChunkName: "OpsAuth" */ "@/pages/Auth/ImpersonateUser.vue"),
        meta: {
            title: "Impersonate user",
            middleware: [],
        }
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            async beforeRouteEnter(to, from, next) {
                const store = userStore();
                await store.logout(true);

                let target = document.querySelector('html');

                let mode = useColorMode({
                    selector: target,
                });

                mode.value = '';

                next({name: 'login'});
            }
        }
    },
];