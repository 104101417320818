export default {
    de: {
        administrators: {
            title: 'Administratoren',
            title_invite: 'Administrator einladen',
            title_delete_invite: 'Einladung löschen',
            title_delete_access: 'Administrator entfernen',
            open_invites: 'Offene Einladungen',
            invite_success: 'Die Einladung an {email} wurde erfolgreich verschickt.',
            description_invite_delete: 'Möchten Sie die Einladung an {email} wirklich löschen?',
            description_delete_access: 'Wollen Sie den Administrator {email} wirklich entfernen?',
            invite_delete_success: 'Die Einladung an {email} wurde erfolgreich gelöscht.',
            access_delete_success: 'Der Administrator {email} wurde erfolgreich entfernt.',
            invite_button: 'Administrator einladen',
            cannot_delete_access: 'Bitte beachten Sie: Der Administrator <strong>{email}</strong> konnte nicht entfernt werden. Der Administrator ist ein Manager des Kunden und kann nicht entfernt werden. Für die Aufhebung der Administrator-Berechtigung muss daher zunächst die Manager-Berechtigung entfernt werden.',
            force_two_factor: {
                tooltip: 'Die 2FA ist für diesen Cluster verpflichtend.',
                activate: {
                    label: '2FA Pflicht aktivieren',
                    title: '2FA Pflicht aktivieren',
                    notice: 'Bitte beachten Sie: Um die 2FA-Pflicht zu aktivieren, muss die 2FA für Ihren Benutzer bereits konfiguriert sein.',
                    description: 'Möchten Sie den Zugriff auf den Cluster <strong>{cluster}</strong> mit einer verpflichtenden Zwei-Faktor-Authentifizierung absichern? Benutzer können dann nur mit aktivierter Zwei-Faktor-Authentifizierung das Managed und Monitoring Center des Clusters <strong>{cluster}</strong> nutzen.<br><br><strong>Hinweis:</strong> Mit Aktivierung der 2FA-Pflicht erhalten alle Benutzer mit Berechtigungen für den Cluster <strong>{cluster}</strong>, die die Zwei-Faktor-Authentifizierung nicht aktiviert haben, eine E-Mail-Benachrichtigung.',
                    success: 'Die 2FA Pflicht wurde für den Cluster <strong>{cluster}</strong> erfolgreich aktiviert.'
                },
                deactivate: {
                    label: '2FA Pflicht deaktivieren',
                    title: '2FA Pflicht deaktivieren',
                    description: 'Möchten Sie die Pflicht zur Zwei-Faktor-Authentifizierung wirklich deaktivieren? Damit ist für die Benutzer mit Zugriffsberechtigung auf Cluster <strong>{cluster}</strong> keine Verpflichtung zur 2FA mehr notwendig.',
                    success: 'Die 2FA Pflicht wurde für den Cluster <strong>{cluster}</strong> erfolgreich deaktiviert.',
                }
            }
        },
        managers: {
            title: 'Manager',
            title_invite: 'Manager einladen',
            title_delete_invite: 'Einladung löschen',
            title_delete_access: 'Manager entfernen',
            open_invites: 'Offene Einladungen',
            invite_success: 'Die Einladung an {email} wurde erfolgreich verschickt.',
            access_delete_success: 'Der Manager {email} wurde erfolgreich entfernt.',
            description_invite_delete: 'Möchten Sie die Einladung an {email} wirklich löschen?',
            description_delete_access: 'Wollen Sie den Manager {email} wirklich entfernen?',
            invite_delete_success: 'Die Einladung an {email} wurde erfolgreich gelöscht.',
            invite_button: 'Manager einladen',
            cannot_delete_access: 'Der Manager <strong>{email}</strong> konnte nicht entfernt werden. Zu jedem Kunden muss mindestens ein Manager vorhanden sein.'
        },
        no_invites: 'Es sind keine offenen Einladungen vorhanden.',
        payment: {
            bic: 'BIC',
            iban: 'IBAN',
            owner: 'Kontoinhaber',
            reference: 'Referenz',
            edit_payment: 'Zahlungsweise bearbeiten',
            transfer_info: 'Bitte beachten Sie: Bei einem Wechsel der Zahlungsart von Lastschrift oder Kreditkarte zu Überweisung müssen aktuell bereits ausgestellte, aber noch unbeglichene Rechnungen, ebenfalls per Überweisung bezahlt werden.',
            apply_not_to_open_invoices: 'Die neuen Zahlungsinformationen sollen für zukünftige Rechnungen gelten. Aktuell bereits ausgestellte, aber noch unbeglichene Rechnungen, werde ich per Überweisung zahlen.',
            apply_to_open_invoices: 'Die neuen Zahlungsinformationen sollen sowohl für zukünftige Rechnungen gelten, als auch für aktuell bereits ausgestellte Rechnungen, die noch unbeglichen sind.',
            allow_debit: 'Ich autorisiere die maxcluster GmbH, Zahlungen vom oben genannten Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der maxcluster GmbH auf oben genanntes Konto gezogenen Lastschriften einzulösen.',
            debit_info: 'Hinweis: Beginnend mit dem Datum der Belastung, kann ich innerhalb von 8 Wochen die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.',
            debit_info_iban: 'Wir unterstützten ausschließlich IBANs aus Ländern des Euro-Währungsraums.',
            credit_card_token_already_used: 'Die Zahlungsinformationen konnten nicht geändert werden, da der temporär erstellte Kreditkartentoken bereits verwendet wurde. Bitte versuchen Sie es erneut oder verwenden Sie eine neue Zahlungsmethode. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unser Support-Team.',
            credit_card_token_expired: 'Die Zahlungsinformationen konnten nicht geändert werden, da der temporär erstellte Kreditkartentoken abgelaufen ist. Bitte versuchen Sie es erneut oder verwenden Sie eine neue Zahlungsmethode. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an unser Support-Team.'
        },
        contact_person_edit: {
            title: 'Ansprechpartner bearbeiten',
            current: 'Aktueller Ansprechpartner',
            description: 'Es können nur aktive Manager als Ansprechpartner ausgewählt werden.',
            email: 'E-Mail',
            name: 'Name'
        },
        domain_handles: {
            overview: {
                title: 'Handle-Übersicht',
                no_handles: 'Es sind keine Handles vorhanden.',
                table: {
                    handle: 'Handle',
                    company: 'Unternehmen',
                    contact_person: 'Ansprechpartner',
                },
            },
            delete_handle: {
                title: 'Handle löschen',
                description: 'Wollen Sie den Handle {handle} wirklich löschen?',
                success: 'Der Handle {handle} wurde erfolgreich gelöscht.',
                error: 'Der Handle {handle} konnte nicht gelöscht werden.',
                error_existing_contract: 'Der Handle {handle} konnte nicht gelöscht werden, da er noch in einem Vertrag verwendet wird.'
            },
            form: {
                title: 'Handle anlegen',
                title_edit: 'Handle bearbeiten - {handle}',
                description: 'Bitte geben Sie die Kontaktdaten für das neue Handle ein:',
                company: 'Unternehmen inkl. Rechtsform',
                firstname: 'Vorname',
                surname: 'Nachname',
                street: 'Straße',
                email: 'E-Mail-Adresse',
                phone: 'Telefonnummer',
                fax: 'Faxnummer',
                id_card_number: 'Personalausweisnummer',
                birthday: 'Geburtsdatum',
                city: 'Stadt',
                country: 'Land',
                postal_code: 'PLZ',
                company_registration_number: 'Handelsregisternummer',
                tax_id: 'Umsatzsteuer-ID',
                updated: 'Der Handle {handle} wurde erfolgreich aktualisiert.',
                created: 'Der Handle {handle} wurde erfolgreich angelegt.',
            },
        },
        dpa: {
            dpa: 'AVV',
            dpa_attachment_1: 'Anhang I: Gegenstand der Auftragsverarbeitung, Umfang und Zweck der Datenverarbeitung, Art der verarbeiteten Daten und Kreis der Betroffenen',
            dpa_attachment_2: 'Anhang II: Technische und organisatorische Maßnahmen gemäß Art. 32 DSGVO',
            dpa_attachment_3: 'Anhang III: Liste der Unterauftragsverarbeiter'
        }
    },
    en: {
        administrators: {
            title: 'Administrators',
            title_invite: 'Invite administrator',
            title_delete_invite: 'Delete invitation',
            title_delete_access: 'Remove administrator',
            open_invites: 'Open invitations',
            invite_success: 'The invitation to {email} was sent successfully.',
            description_invite_delete: 'Are you sure you want to delete the invitation to {email}?',
            description_delete_access: 'Are you sure you want to remove the administrator {email}?',
            invite_delete_success: 'The invitation to {email} was successfully deleted.',
            invite_button: 'Invite administrator',
            access_delete_success: 'The administrator {email} was successfully removed.',
            cannot_delete_access: 'Please note: The administrator <strong>{email}</strong> could not be removed. The administrator is a manager of the customer and cannot be removed. Therefore, the manager authorization must first be removed in order to revoke the administrator authorization.',
            force_two_factor: {
                tooltip: '2FA is mandatory for this cluster.',
                activate: {
                    label: 'Activate 2FA requirement',
                    title: 'Activate 2FA requirement',
                    notice: 'Please note: To activate the 2FA requirement, the 2FA must already be configured for your user.',
                    description: 'Do you want to secure access to the cluster <strong>{cluster}</strong> with a mandatory two-factor authentication? Users can then only use the Managed and Monitoring Center of the cluster <strong>{cluster}</strong> with activated two-factor authentication.<br><br><strong>Note:</strong> By activating the 2FA requirement, all users with permissions for the cluster <strong>{cluster}</strong> who have not activated two-factor authentication will receive an email notification.',
                    success: 'The 2FA requirement for the cluster <strong>{cluster}</strong> was successfully activated.'
                },
                deactivate: {
                    label: 'Deactivate 2FA requirement',
                    title: 'Deactivate 2FA requirement',
                    description: 'Do you really want to deactivate the requirement for two-factor authentication? This means that users with access permissions to cluster <strong>{cluster}</strong> no longer need to use 2FA.',
                    success: 'The 2FA requirement for the cluster <strong>{cluster}</strong> was successfully deactivated.',
                }
            }
        },
        managers: {
            title: 'Manager',
            title_invite: 'Invite manager',
            title_delete_invite: 'Delete invitation',
            title_delete_access: 'Remove manager',
            open_invites: 'Open invitations',
            invite_success: 'The invitation to {email} was sent successfully.',
            description_invite_delete: 'Are you sure you want to delete the invitation to {email}?',
            description_delete_access: 'Are you sure you want to remove the manager {email}?',
            invite_delete_success: 'The invitation to {email} was successfully deleted.',
            invite_button: 'Invite manager',
            access_delete_success: 'The manager {email} was successfully removed.',
            cannot_delete_access: 'The manager <strong>{email}</strong> could not be removed. Each customer must have at least one manager.'
        },
        no_invites: 'There are no open invites.',
        payment: {
            bic: 'BIC',
            iban: 'IBAN',
            owner: 'Account holder',
            reference: 'Reference',
            edit_payment: 'Edit payment method',
            transfer_info: 'Please note: If you change the payment method from direct debit or credit card to bank transfer, currently issued but not yet paid invoices must also be paid by bank transfer.',
            apply_not_to_open_invoices: 'The new payment information should apply to future invoices. I will pay already issued but not yet paid invoices by bank transfer.',
            apply_to_open_invoices: 'The new payment information should apply to both future invoices and currently issued invoices that are still unpaid.',
            allow_debit: 'I authorize maxcluster GmbH to collect payments from the above-mentioned account by direct debit. At the same time, I instruct my bank to honor the direct debits drawn by maxcluster GmbH on the above-mentioned account.',
            debit_info: 'Starting from the date of the debit, I can demand reimbursement of the amount debited within 8 weeks. The conditions agreed with my bank apply.',
            debit_info_iban: 'We only support IBANs from countries in the euro currency area.',
            credit_card_token_already_used: 'The payment information could not be changed because the temporarily created credit card token has already been used. Please try again or use a new payment method. If the issue persists, please contact our support team for assistance.',
            credit_card_token_expired: 'The payment information could not be changed because the temporarily created credit card token has expired. Please try again or use a new payment method. If the issue persists, please contact our support team for assistance.'
        },
        contact_person_edit: {
            title: 'Edit contact person',
            current: 'Current contact person',
            description: 'Only active managers can be selected as contact persons.',
            email: 'Email',
            name: 'Name'
        },
        domain_handles: {
            overview: {
                title: 'Handle overview',
                no_handles: 'There are no handles.',
                table: {
                    handle: 'Handle',
                    company: 'Company',
                    contact_person: 'Contact person',
                },
            },
            delete_handle: {
                title: 'Delete handle',
                description: 'Do you really want to delete the handle {handle}?',
                success: 'The handle {handle} was successfully deleted.',
                error: 'The handle {handle} could not be deleted.',
                error_existing_contract: 'The handle {handle} could not be deleted because it is still used in a contract.'
            },
            form: {
                title: 'Create handle',
                title_edit: 'Edit handle - {handle}',
                description: 'Please enter the contact details for the new handle:',
                company: 'Company incl. legal form',
                firstname: 'Firstname',
                surname: 'Surname',
                street: 'Street',
                email: 'E-Mail address',
                phone: 'Phone number',
                fax: 'Fax number',
                id_card_number: 'ID card number',
                birthday: 'Birthday',
                city: 'City',
                country: 'Country',
                postal_code: 'Postal code',
                company_registration_number: 'Company registration number',
                tax_id: 'Tax ID',
                updated: 'The handle {handle} was successfully updated.',
                created: 'The handle {handle} was successfully created.',
            },
        },
        dpa: {
            dpa: 'DPA',
            dpa_attachment_1: 'Annex I: Subject of data processing, scope and purpose of data processing, type of data processed and group of data subjects',
            dpa_attachment_2: 'Annex II: Technical and organizational measures according to Art. 32 GDPR',
            dpa_attachment_3: 'Annex III: List of sub-processors',
        }
    }
};
